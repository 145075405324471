import React from 'react'
import { StytchProvider } from '@stytch/nextjs'
import { createStytchHeadlessClient } from '@stytch/nextjs/headless'

const stytch = createStytchHeadlessClient(process.env.STYTCH_PUBLIC_TOKEN)

const MyApp = ({ Component, pageProps }) => {
  return (
    <StytchProvider stytch={stytch}>
      <Component {...pageProps} />
    </StytchProvider>
  )
}

export default MyApp
